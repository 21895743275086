// 配置信息
export default {
	
	// HTTP_URL: 'http://127.0.0.1:8802', //服务地址 用于访问静态资源
	// HTTP_API_URL: 'http://127.0.0.1:8802/api', //api接口地址
	// socketurl: 'wss://im.Laredoute-ap.xyz', //如果是ws:// 地址为ws://你服务器IP:7272 如 ws://123.4.56.78:7272，使用wss 后面不需要添加 :7272 端口
	
	HTTP_URL: 'https://www.laredoutemall.vip', //服务地址 用于访问静态资源
	HTTP_API_URL: 'https://www.modclothsapp.vip', //api接口地址
	socketurl: 'wss://im.modclothsapp.vip',
	
	debug: true
}