
// import config from "./config.js";
import { Message } from "element-ui";
import { i18n } from '../locale/index.js' //国际化
let cutAnimationTime = null

// 公共方法
export default {
	
	//判断用户是否登录
	is_login: function(showToast = false, isGoLogin = false){
		let token = uni.getStorageSync("token")
		if(token){
			return true;
		}
		if(showToast){
			setTimeout(()=>{
				uni.showToast({
					title: 'login',
					icon: 'none'
				})
			}, 200)
		}
		if(isGoLogin){
			uni.navigateTo({
				url: '/pages/login/login'
			})
		}
		return false;
	},

	copy(data){
		navigator.clipboard.writeText(data);
		Message.success(i18n.t('text95'))
	},
	// 打开页面
	open_view: function(url, isLogin = false){
		let token = uni.getStorageSync("token")
		if(isLogin && !token){
			uni.navigateTo({
				url: '/pages/login/login'
			})
			return false;
		}
		uni.navigateTo({
			url: url
		})
	},
	//返回
	back: function(){
		let pages = getCurrentPages()
		// 有可返回的页面则直接返回
		if (pages.length > 1) {
			uni.navigateBack()
			return
		}
		// 返回home页面
		uni.reLaunch({ url: "/pages/index" })
		return
	},
	
	
	
	/**
	 * @desc 去左右空格
	 * @param value 需要处理的字符串
	 **/
	trim: function(value) {
		return value.replace(/(^\s*)|(\s*$)/g, "");
	},
	/**
	 * @desc 去所有空格
	 * @param value 需要处理的字符串
	 **/
	trimAll: function(value) {
		return value.replace(/\s+/g, "")
	},
	/**
	 * @desc 替换所有相同字符串
	 * @param text 需要处理的字符串
	 * @param repstr 被替换的字符
	 * @param newstr 替换后的字符
	 **/
	replaceAll: function(text, repstr, newstr) {
		return text.replace(new RegExp(repstr, "gm"), newstr);
	},
	/**
	 * @desc 格式化手机号码
	 * @param num 手机号码
	 **/
	formatNumber: function(num) {
		return num.length === 11 ? num.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') : num;
	},
	/**
	 * @desc 金额格式化，保留两位小数
	 * @param money 金额值
	 **/
	formatMoney: function(money = 0, point = 2) {
		//分割小数点 格式化整数
		let arr = money.toString().split('.');
		//整数
		let num = arr[0].split('').reverse().join('').replace(/(\d{3})/g, '$1,')
		.replace(/\,$/, '').split('').reverse().join('');
		if(point){
			let point_num = '' //小数
			if(arr[1]) point_num = arr[1]
			for(let i=0;i<point;i++){//补0
				point_num = point_num + '0'
			}
			num = num + '.' + point_num.substring(0, point)
		}
		
		return num.replace('-,', '-')
		// return parseFloat(money).toFixed(point).toString().split('').reverse().join('').replace(/(\d{3})/g, '$1,')
		// 	.replace(/\,$/, '').split('').reverse().join('');
	},
	/**
	 * @desc 金额格式化，保留两位小数
	 * @param money 金额值
	 **/
	formatMoneyUnit: function(money = 0, point = 2) {
		
		//分割小数点 格式化整数
		let arr = money.toString().split('.');
		//整数
		let num = arr[0].split('').reverse().join('').replace(/(\d{3})/g, '$1,')
		.replace(/\,$/, '').split('').reverse().join('');
		if(point){
			let point_num = '' //小数
			if(arr[1]) point_num = arr[1]
			for(let i=0;i<point;i++){//补0
				point_num = point_num + '0'
			}
			num = num + '.' + point_num.substring(0, point)
		}
		
		num = num.replace('-,', '-')
		
		let money_type = window.localStorage.getItem("moneyType")
		if(money_type == 'en'){
			num = 'US$' + num
		}else if(money_type == 'rb'){
			num = '￥' + num
		}else if(money_type == 'hg'){
			num = num+'원'
		}else if(money_type == 'yn'){
			num = '₫' + num
		}else if(money_type == 'xg'){
			num = 'HK$' + num
		}else if(money_type == 'tw'){
			num = 'NT$' + num
		}else{
			num = 'US$' + num
		}
		return num;
	},
	/**
	 * @desc 唯一标识，随机数
	 * @param n 随机数位数
	 **/
	unique: function(n) {
		n = n || 6;
		let rnd = '';
		for (let i = 0; i < n; i++)
			rnd += Math.floor(Math.random() * 10);
		return 'tui_' + new Date().getTime() + rnd;
	},
	/**
	 * @desc 获取uuid
	 */
	getUUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
			return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
		})
	},
	/**
	 * @desc 简单数组合并去重
	 * @param arr1 数组1
	 * @param arr2 数组2 可不传
	 **/
	distinctArray: function(arr1, arr2) {
		arr1 = arr1 || []
		arr2 = arr2 || []
		return [...new Set([...arr1, ...arr2])]
	},
	
	/**
	 * @desc 获取日期时间段
	 * @param type 1-今天 2-昨天 3-本周 4-本月 5-本年
	 **/
	getDateTimeSlot: function(type) {
		let now = new Date()
		let start = now.toDateString()
		let end = now.toDateString()
		switch (type) {
			case 1:
				start = `${start} 00:00:00`
				end = `${end} 23:59:59`
				break
			case 2:
				now.setTime(now.getTime() - 3600 * 1000 * 24 * 1)
				start = `${now.toDateString()} 00:00:00`
				end = `${now.toDateString()} 23:59:59`
				break
			case 3:
				// 获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7
				let weekday = now.getDay() || 7
				// 往前算（weekday-1）天，年份、月份会自动变化
				now.setDate(now.getDate() - weekday + 1)
				start = `${now.toDateString()} 00:00:00`
				end = `${end} 23:59:59`
				break
			case 4:
				start = `${now.getFullYear()}-${now.getMonth() + 1}-01 00:00:00`
				end = `${end} 23:59:59`
				break
			case 5:
				start = `${now.getFullYear()}-01-01 00:00:00`
				end = `${end} 23:59:59`
				break
			default:
				break
		}
	
		return {
			start: new Date(start.replace(/\-/g, '/')),
			end: new Date(end.replace(/\-/g, '/'))
		}
	},
	/*
	 * @desc 获取Url参数，返回一个对象
	 * @param url url地址
	 * ?a=1&b=2 ==> {a: "1", b: "2"}
	 */
	getUrlParam(url) {
		let arrObj = url.split("?");
		let params = {};
		if (arrObj.length > 1) {
			arrObj = arrObj[1].split("&");
			arrObj.forEach(item => {
				item = item.split("=");
				params[item[0]] = item[1];
			})
		}
		return params;
	},
	/**
	 * @method 函数防抖
	 * @desc 短时间内多次触发同一事件，只执行最后一次，或者只执行最开始的一次，中间的不执行。
	 * @param func 目标函数
	 * @param wait 延迟执行毫秒数
	 * @param immediate true - 立即执行， false - 延迟执行
	 */
	debounce(func, wait = 1000, immediate = true) {
		let timer;
		return function() {
			let context = this,
				args = arguments;
			if (timer) clearTimeout(timer);
			if (immediate) {
				let callNow = !timer;
				timer = setTimeout(() => {
					timer = null;
				}, wait);
				if (callNow) func.apply(context, args);
			} else {
				timer = setTimeout(() => {
					func.apply(context, args);
				}, wait)
			}
		}
	},
	/**
	 * @method 函数节流
	 * @desc 指连续触发事件，但是在 n 秒内只执行一次函数。即 2n 秒内执行 2 次... 。会稀释函数的执行频率。
	 * @param func 函数
	 * @param wait 延迟执行毫秒数
	 * @param type 1 在时间段开始的时候触发 2 在时间段结束的时候触发
	 */
	throttle(func, wait = 1000, type = 1) {
		let previous = 0;
		let timeout;
		return function() {
			let context = this;
			let args = arguments;
			if (type === 1) {
				let now = Date.now();
				if (now - previous > wait) {
					func.apply(context, args);
					previous = now;
				}
			} else if (type === 2) {
				if (!timeout) {
					timeout = setTimeout(() => {
						timeout = null;
						func.apply(context, args)
					}, wait)
				}
			}
		}
	},
	
	
	
	
	
	
}
