<template>
  <div data-v-19da6b64="" class="login-count">
    <div data-v-19da6b64="" class="wrapper-count">
      <span data-v-19da6b64="" class="closeBtn iconfont icon-guanbi2" @click="$store.state.loginDilaog = false"></span>
      <div data-v-19da6b64="" class="wrapper">
        <div data-v-19da6b64="" class="title">
          <span data-v-19da6b64="" class="item_title font_red">{{ $t("text19") }}</span>
        </div>
        <div data-v-19da6b64="" class="item phone acea-row row-middle">
          <input data-v-19da6b64="" type="text" v-model="account" :placeholder="$t('login.account') " />
        </div>
        <div data-v-19da6b64="" class="item pwd">
          <input data-v-19da6b64="" type="password" v-model="password" :placeholder="$t('login.password')" />
        </div>
        <div data-v-19da6b64="" class="checkbox-wrapper item_protocol">
          <label data-v-19da6b64="" class="well-check"
            >
            
            <el-checkbox v-model="checked" style="margin-left: -20px;margin-right: 10px"></el-checkbox>


            <span data-v-19da6b64="">{{ $t("register.tip") }}</span></label
          >
          <a
            data-v-19da6b64=""
            @click="$store.commit('clearDiloag');$router.push({
              path:'/privacy_agreement',
              query:{
                type:1
              }
            })"
            class="show_protocol"
            target="_blank"
            >{{ $t("register.yfxy") }}
          </a>
          {{ $t("register.and") }}
          <a
            data-v-19da6b64=""
            @click="$store.commit('clearDiloag');$router.push({
              path:'/privacy_agreement',
              query:{
                type:2
              }
            })"
            class="show_protocol"
            target="_blank"
            > {{ $t("register.yszc") }}
          </a>


        </div>
        <div data-v-19da6b64="" class="signIn bg-color" @click="login">{{ $t("login.login") }}</div>
        <div style="display: flex;align-items: center;justify-content: space-between;padding:0 45px; ">
          <div data-v-19da6b64="" class="fastLogin">
           {{ $t("text20") }} <span data-v-19da6b64=""  class="font-color" @click="$store.state.loginDilaog=false;$store.state.registeredDialog=true;">{{ $t("register.register") }}</span>
        </div>
        <div  data-v-19da6b64="" class="fastLogin"
           @click="$store.state.loginDilaog=false;$store.state.forgetDialog=true;"
        > <span  class="font-color">{{ $t("login.forgot") }}</span> </div>
        </div>
       
      </div>
    
    </div>
  </div>
</template>



<script>

export default {

  data() {
    return{
        account:"",
        password:'',
        checked:true
    }
  },
  methods:{
    login(){
        if(!this.checked){
          return this.$message.error(this.$t("register.sTip"))
        }

        if(!this.account){
            return this.$message.error(this.$t("login.email"))
        }
        if(!this.password){
            return this.$message.error(this.$t("login.password"))
        }
        this.$axios({
            method:"POST",
            url:"/api/wanlshop/user/login",
			data:{
				account:this.account,
				password:this.password,
				top:""

			}
        }).then(res => {
           if(res.data.code==1){
            this.$store.state.loginStatus = true
			this.$store.state.userData = res.data.data
			localStorage.setItem("userData",JSON.stringify(res.data.data) )
			localStorage.setItem("token",JSON.stringify(res.data.data.userinfo.token) )
      this.$store.state.token = res.data.data.userinfo.token
			this.$message.success(this.$t("text23"))
            this.$store.state.loginDilaog = false
			
		   }
        })
    },
   
  },

}

</script>