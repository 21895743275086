var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadingHome)?_c('div',{staticClass:"loading-wrapper"},[_vm._m(0)]):_c('div',{staticClass:"demo-image__lazy",staticStyle:{"overflow":"auto","height":"100vh"},attrs:{"id":"__nuxt"}},[_c('div',{attrs:{"id":"__layout"}},[_c('div',[_vm._m(1),_c('HeaderTop'),_c('div',{attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"index wrapper_1200",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"categoryMain",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"wrapper_1200",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"list wrapper_1200 acea-row row-middle",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"categoryBtn",attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(_vm.$t("classify.classify")))]),_c('a',{staticClass:"item",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.$router.push('/goods_cate')}}},[_vm._v(_vm._s(_vm.$t("text4"))+" ")])])])]),_c('div',{staticClass:"wrapper",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"slider-banner banner",attrs:{"data-v-020511ba":""}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-container swiper-container-initialized swiper-container-horizontal",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"swiper-wrapper",staticStyle:{"transform":"translate3d(-3600px, 0px, 0px)","transition-duration":"1000ms"},attrs:{"data-v-020511ba":""}},_vm._l((_vm.banner_list),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide",staticStyle:{"margin-left":"208px","width":"992px"},attrs:{"data-v-020511ba":""}},[_c('img',{staticStyle:{"width":"992px"},attrs:{"data-v-020511ba":"","src":item.image}})])}),0)])]),_c('div',{staticClass:"category acea-row row-middle",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"sort sort-scroll",attrs:{"data-v-020511ba":""}},_vm._l((_vm.init_data?.modulesData
                    ?.categoryModules),function(item,index){return _c('div',{key:index,staticClass:"item acea-row row-between-wrapper",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.goods_cate(item)}}},[_c('div',{staticClass:"name line1",attrs:{"data-v-020511ba":""}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"iconfont icon-you",attrs:{"data-v-020511ba":""}})])}),0)])]),_c('div',{staticClass:"seckill acea-row row-middle",attrs:{"data-v-020511ba":""}}),_vm._m(2),_c('div',{staticClass:"newGoods",attrs:{"data-v-020511ba":"","id":"page3"}},[_c('div',{staticClass:"publicTitle acea-row row-between-wrapper",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"title acea-row row-middle",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(_vm.$t('index.Recommended')))])]),_c('a',{staticClass:"more acea-row row-center-wrapper",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.getindex_goods_list()}}},[_vm._v(" "+_vm._s(_vm.$t("replace"))+" "),_c('el-icon',{staticClass:"el-icon-refresh"})],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list acea-row row-middle",staticStyle:{"display":"flex"},attrs:{"data-v-020511ba":""}},_vm._l((_vm.goods_list.list),function(item,index){return _c('div',{key:index,staticClass:"item",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.$router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })}}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_c('el-image',{staticStyle:{"width":"192px","height":"192px"},attrs:{"src":item.goods.image,"fit":'scale-down',"lazy":""}})],1),_c('div',{staticClass:"name line1",attrs:{"data-v-020511ba":""}},[_vm._v(" "+_vm._s(item.goods.title)+" ")]),_c('div',{staticClass:"money line1",attrs:{"data-v-020511ba":""}},[_c('span',{staticClass:"label",staticStyle:{"padding":"0 10px"},attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(_vm.$t("product.list.price")))]),_c('span',{staticClass:"font-color",attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(item.goods.hb_unit)+" "+_vm._s(item.goods.price.toFixed(2)))])]),_c('div',{staticClass:"stock",attrs:{"data-v-020511ba":""}},[_vm._v(" "+_vm._s(_vm.$t("product.goods.sales"))+" "+_vm._s(item.sales)+" ")])])}),0)]),_c('div',{staticClass:"newGoods",attrs:{"data-v-020511ba":"","id":"page3"}},[_c('div',{staticClass:"publicTitle acea-row row-between-wrapper",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"title acea-row row-middle",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(_vm.$t("index.hot")))])]),_c('a',{staticClass:"more acea-row row-center-wrapper",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.getindex_goods_list()}}},[_vm._v(" "+_vm._s(_vm.$t("replace"))+" "),_c('el-icon',{staticClass:"el-icon-refresh"})],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list acea-row row-middle",staticStyle:{"display":"flex"},attrs:{"data-v-020511ba":""}},_vm._l((_vm.goods_list.list2),function(item,index){return _c('div',{key:index,staticClass:"item",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.$router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })}}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_c('el-image',{staticStyle:{"width":"192px","height":"192px"},attrs:{"lazy":"","src":item.goods.image,"fit":'scale-down'}})],1),_c('div',{staticClass:"name line2",attrs:{"data-v-020511ba":""}},[_vm._v(" "+_vm._s(item.goods.title)+" ")]),_c('div',{staticClass:"money line1",attrs:{"data-v-020511ba":""}},[_c('span',{staticClass:"font-color",staticStyle:{"margin-left":"0"},attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(item.goods.hb_unit)+" "+_vm._s(item.goods.price.toFixed(2)))])])])}),0)]),_c('div',{staticClass:"newGoods",attrs:{"data-v-020511ba":"","id":"page3"}},[_c('div',{staticClass:"publicTitle acea-row row-between-wrapper",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"title acea-row row-middle",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(_vm.$t("goodList")))])]),_c('a',{staticClass:"more acea-row row-center-wrapper",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.getgoods_list()}}},[_vm._v(" "+_vm._s(_vm.$t("replace"))+" "),_c('el-icon',{staticClass:"el-icon-refresh"})],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading1),expression:"loading1"}],staticClass:"list acea-row row-middle",staticStyle:{"display":"flex"},attrs:{"data-v-020511ba":""}},_vm._l((_vm.goods_list2),function(item,index){return _c('div',{key:index,staticClass:"item",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.$router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })}}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_c('el-image',{staticStyle:{"width":"192px","height":"192px"},attrs:{"lazy":"","src":item.goods.image,"fit":'scale-down'}})],1),_c('div',{staticClass:"name line2",attrs:{"data-v-020511ba":""}},[_vm._v(" "+_vm._s(item.goods.title)+" ")]),_c('div',{staticClass:"money line1",attrs:{"data-v-020511ba":""}},[_c('span',{staticClass:"font-color",staticStyle:{"margin-left":"0"},attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(item.goods.hb_unit)+" "+_vm._s(item.goods.price.toFixed(2)))])])])}),0)]),_c('div',{staticClass:"classify",attrs:{"data-v-020511ba":"","id":" page4"}},[_c('div',{staticClass:"classify-title",attrs:{"data-v-020511ba":""}},[_c('span',{attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(_vm.$t("tabBar.classify")))])])]),_vm._l((_vm.init_data?.modulesData?.categoryModules),function(item1,index1){return (_vm.page > index1)?_c('div',{key:index1,class:'newGoods newGoods' + index1,attrs:{"data-v-020511ba":"","id":"page3"}},[_c('div',{staticClass:"publicTitle acea-row row-between-wrapper",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"title acea-row row-middle",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(item1.name))])]),_c('a',{staticClass:"more acea-row row-center-wrapper",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.$router.push({
                    path: '/goods_cate',
                    query: {
                      category_id: item1.id,
                      name: item1.name,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.$t("text7"))+" "),_c('el-icon',{staticClass:"el-icon-arrow-right"})],1)]),_c('div',{staticClass:"list acea-row row-middle",staticStyle:{"display":"flex"},attrs:{"data-v-020511ba":""}},_vm._l((item1?.data),function(item,index){return _c('div',{key:index,staticClass:"item",attrs:{"data-v-020511ba":""},on:{"click":function($event){return _vm.$router.push({
                    path: '/productDetails',
                    query: {
                      id: item.id,
                    },
                  })}}},[_c('div',{staticClass:"pictrue",attrs:{"data-v-020511ba":""}},[_c('el-image',{staticStyle:{"width":"192px","height":"192px"},attrs:{"src":item.goods.image,"fit":'scale-down',"lazy":""}})],1),_c('div',{staticClass:"name line2",attrs:{"data-v-020511ba":""}},[_vm._v(" "+_vm._s(item.goods.title)+" ")]),_c('div',{staticClass:"money line1",attrs:{"data-v-020511ba":""}},[_c('span',{staticClass:"font-color",staticStyle:{"margin-left":"0"},attrs:{"data-v-020511ba":""}},[_vm._v(_vm._s(item.goods.hb_unit)+" "+_vm._s(item.goods.price.toFixed(2)))])])])}),0)]):_vm._e()}),(_vm.init_data?.modulesData?.categoryModules.length > _vm.page)?_c('div',{staticStyle:{"text-align":"center"},attrs:{"id":"loadmore"}},[_c('i',{staticClass:"el-icon-loading"})]):_c('div',{staticStyle:{"text-align":"center"},attrs:{"id":"loadmore"}})],2)]),_c('BottomFixed')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"cart-icon"}),_c('span',{staticClass:"loading-text"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-v-3df7a256":""}},[_c('div',{staticClass:"index_banner",attrs:{"data-v-3df7a256":""}},[_c('img',{attrs:{"data-v-3df7a256":"","src":"https://www.magasings.com/"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boutique",attrs:{"data-v-020511ba":"","id":"page2"}},[_c('div',{staticClass:"publicTitle acea-row row-between-wrapper",attrs:{"data-v-020511ba":""}},[_c('div',{staticClass:"title acea-row row-middle",attrs:{"data-v-020511ba":""}})])])
}]

export { render, staticRenderFns }