import en from './en.json'
import zhHans from './zh-Hans.json'
import zhHant from './zh-Hant.json'
import kor from './kor.json'
import jp from './jp.json'
import vnm from './vnm.json'
import xby from './xby.json'
import pty from './pty.json'
import dy from './dy.json'
import ydl from './ydl.json'



import Vue from "vue";
import VueI18n from "vue-i18n";
 
Vue.use(VueI18n); // 全局挂载
 
export const i18n = new VueI18n({
  locale: localStorage.getItem("country") || "en", // 从localStorage中获取 默认英文
  messages: {
	en,
	'zh-Hans': zhHans,
	'zh-Hant': zhHant,
	'kor': kor,
	'jp': jp,
	'vnm': vnm,
	'xby':xby,
	'pty':pty,
	'dy':dy,
	'ydl':ydl,
  }
});
 
export default i18n;